import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from "react-ga";
import "react-toastify/dist/ReactToastify.css";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", contactSubject: "", message: "" };
  }

  handleSubmit = (e) => {
    ReactGA.event({
      category: "contact form submit button",
      action: "Someone is reaching out to you.",
    });
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then((response) => {
        this.setState({ name: "", email: "", contactSubject: "", message: "" });
        if (response.ok) {
          toast("Thank you for showing intrest in my profile!");
        } else {
          toast("Something went wrong, Please try again.");
        }
      })
      .catch((error) => toast("Something went wrong, Please try again."));
    e.preventDefault();
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
  handleNotify = () => toast("Thank you for showing intrest in my profile!");

  render() {
    const { name, email, contactSubject, message } = this.state;
    if (this.props.data) {
      var textMessage = this.props.data.contactmessage;
    }

    return (
      <section id="contact">
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Get In Touch.</span>
            </h1>
          </div>
          <div className="ten columns">
            <p className="lead">{textMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="ten columns">
            <form onSubmit={this.handleSubmit}>
              <fieldset>
                <div>
                  <label htmlFor="contactName">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    size="35"
                    id="contactName"
                    pattern="[A-Za-z]{1,255}"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="contactEmail">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    size="35"
                    id="contactEmail"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="contactSubject">Subject</label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    id="contactSubject"
                    name="contactSubject"
                    value={contactSubject}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="contactMessage">
                    Message <span className="required">*</span>
                  </label>
                  <textarea
                    cols="50"
                    rows="5"
                    value={message}
                    id="contactMessage"
                    name="message"
                    onChange={this.handleChange}
                    required
                  ></textarea>
                </div>
                <p>
                  <button className="submit" type="submit">
                    Submit
                  </button>
                </p>
              </fieldset>
            </form>
          </div>
          <ToastContainer />
        </div>
      </section>
    );
  }
}

export default Contact;
